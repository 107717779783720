// 显示当前时间（年月日时分秒星期）
export function timeFormate () {
  const td = new Date()
  const year = new Date(td).getFullYear()
  const month =
    new Date(td).getMonth() + 1 < 10
      ? '0' + (new Date(td).getMonth() + 1)
      : new Date(td).getMonth() + 1
  const date =
    new Date(td).getDate() < 10
      ? '0' + new Date(td).getDate()
      : new Date(td).getDate()
  const hh =
    new Date(td).getHours() < 10
      ? '0' + new Date(td).getHours()
      : new Date(td).getHours()
  const mm =
    new Date(td).getMinutes() < 10
      ? '0' + new Date(td).getMinutes()
      : new Date(td).getMinutes()
  const ss =
    new Date(td).getSeconds() < 10
      ? '0' + new Date(td).getSeconds()
      : new Date(td).getSeconds()
  const week = new Date(td).getDay()
  const weeks = ['日', '一', '二', '三', '四', '五', '六']
  const getWeek = '星期' + weeks[week]
  const timeStamp = new Date().getTime()
  return {
    date: year + '年' + month + '月' + date + '日',
    time: hh + ':' + mm + ':' + ss,
    week: getWeek,
    timeStamp: timeStamp
  }
}
// 按日期时间格式显示当前时间(yyyy-MM-dd hh:mm:ss)
export function dateFormate (formatStr) {
  const td = new Date()
  const year = new Date(td).getFullYear()
  const month =
    new Date(td).getMonth() + 1 < 10
      ? '0' + (new Date(td).getMonth() + 1)
      : new Date(td).getMonth() + 1
  const date =
    new Date(td).getDate() < 10
      ? '0' + new Date(td).getDate()
      : new Date(td).getDate()
  const hh =
    new Date(td).getHours() < 10
      ? '0' + new Date(td).getHours()
      : new Date(td).getHours()
  const mm =
    new Date(td).getMinutes() < 10
      ? '0' + new Date(td).getMinutes()
      : new Date(td).getMinutes()
  const ss =
    new Date(td).getSeconds() < 10
      ? '0' + new Date(td).getSeconds()
      : new Date(td).getSeconds()
  var str = formatStr
  str = str.replace(/yyyy|YYYY/, year)
  str = str.replace(/MM/, month)
  str = str.replace(/dd|DD/, date)
  str = str.replace(/hh/, hh)
  str = str.replace(/mm/, mm)
  str = str.replace(/ss/, ss)
  return str
}

export function formatDate (dt, fmt) {  
  if(dt === undefined || dt === '') {    
    return ''
  }
  var date = new Date(dt)
  if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
  };
  for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ''
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
      }
  }
  return fmt
}
function padLeftZero (str) {
  return ('00' + str).substr(str.length)
}


// function timeFormate () {
//   return year + '年' + month + '月' + date + '日' + ' ' + hh + ':' + mm + ':' + ss + ' ' + getWeek
// }
// export { timeFormate, dateFormate }