<!--资源列表-->
<template>
  <!--路径s-->
  <div class="path">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="24" :md="22" :lg="20" >
        <span><router-link to="/"> 首页</router-link></span> &nbsp; &gt; &nbsp; <router-link to="/resource/">产学研资源</router-link> &nbsp; &gt; &nbsp;内容页
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--路径s--> 
  <div class="box-p res">
    <el-row :gutter="0">
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :xs="24" :sm="24" :md="22" :lg="20">            
          <div v-loading="imgLoading">            
            <el-row :gutter="0" class="res-head">
              <el-col :sm="10" :md="10">
                <img v-if="imgData === null" src="@/assets/images/nopic1.jpg" />
                <el-carousel v-else>
                  <el-carousel-item v-for="item in imgData" :key="item">
                    <el-image      
                      :src="this.$api.serverUrl + item.url"                      
                      fit="cover"
                    />
                  </el-carousel-item>
                </el-carousel>
              </el-col>
              <el-col :sm="14" :md="14" style="padding-left: 16px;">
                <div class="res-title">{{data.name}}</div>
                <div class="res-jj">{{data.remark}}</div>
                <div class="res-bottom"><span>来源：{{data.createBy_nick_name}}</span><span>发布：{{formatDate(data.createDate)}}</span><span>服务：{{data.num}}</span></div>
                <div v-if="this.useraudit === '1'" class="res-sq">
                  <el-button type="primary" @click="applyAdd">申 请</el-button>
                </div>                                
              </el-col>
            </el-row>            
            <div v-html="data.content" class="res-content"></div>
          </div>
      </el-col>
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
    </el-row>
  </div> 
</template>

<script>
import {formatDate} from '@/utils/datetime_utils.js'
export default {
  name: 'resource',  
  data () {
    return { 
      //用户审批状态(0:待审批1:通过2:不通过)
      useraudit: '', 
      imgLoading: false,    
      data: {
        id: '',
        name: '',
        content: '',
        remark: '',
        //资源所有人Id
        createBy: '',
        //资源所有人(来源)
        createBy_nick_name: ''
      },
      imgData: null,
      apply: {
        restId: '',
        restName: '',
        createName: '',
        //资源所有人Id
        userId: '',
        //资源所有人
        username: ''        
      },
      srcList: [
        'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
        'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg'        
      ]
    }
  },
  mounted () {
    var id = this.$route.query.id
    if(id === undefined || id === '') {
      this.$message.error('查看参数错误！')
      return
    }
    this.getData(id)

    const ui = this.$api.ui    
    if (ui !== null) {
      this.useraudit = ui.uaudit
      this.apply.createName = ui.nickName
    }
  },
  methods: {
    async getData (id) {
      this.imgLoading = true
      // status=1&
      const params = '?id='+ id
      const { data: ret } = await this.$http.get1(this.$api.resourceshow + params)      
      // console.log('ret=', ret)
      if (ret.code === 1) {
        this.data = ret.data        
        // console.log('Data=', this.data)
        this.showImage(this.data.userId)
      } else {
        this.$message.error(ret.msg)        
      }
      this.imgLoading = false     
    },
    // 显示时间格式'yyyy-MM-dd hh:mm:ss'
    formatDate(time){
      return formatDate(time,'yyyy-MM-dd hh:mm')
    },
    showImage(val){
      if(val === null) {
        this.imgData = null
      } else {
        this.imgData = JSON.parse(val)    
      // var array = []
      // // array = Object.keys(this.cbgIndustry).map(val => ({
      // //     val: this.cbgIndustry[val]
      // // }))
      // // console.log(array)
      // // let obj = JSON.parse(ress)
      // for (let i in this.cbgIndustry ){
      //     array.push(this.cbgIndustry[i])
      // }
      // console.log(array)
      // console.log('this.imgData=', this.imgData)    
      }   
    },
    async applyAdd () {
      this.$confirm('您确定要申请该项资源吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // const loading = this.$loading({
        //   lock: true,
        //   text: '正在加载中...',
        //   //background: 'rgba(0, 0, 0, 0.7)'
        // })        
        this.apply.restId = this.data.id
        this.apply.restName = this.data.name        
        this.apply.userId =this.data.createBy
        this.apply.username =this.data.createBy_nick_name
        console.log('applyform=', this.apply)
        // const { data: ret } = await this.$http.post(this.$api.orderAdd)
        // console.log('ret=', ret)
        // if (ret.code === 1) {
        //   this.$message.success(ret.msg)            
        // } else {
        //   this.$message.error(ret.msg)
        // }
        // loading.close()
      })
    }
  }
}
</script>

<style scoped>
  /* .el-image-viewer__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  } */
</style>